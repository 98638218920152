import React from "react"
import PropTypes from "prop-types"
import {connect} from 'react-redux'
import {history} from "../index";
import {Backdrop, Box} from "@material-ui/core";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles"
import AddAlert from "@material-ui/icons/AddAlert"

// core components
import GridContainer from "components/GridContainer"
import GridItem from "components/GridItem"
import Card from "components/Card"
import CardBody from "components/CardBody"
import CardHeader from "components/CardHeader"
import CardFooter from "components/CardFooter"
import Snackbar from "components/Snackbar"
import RedirectLogin from "../components/RedirectLogin/RedirectLogin.jsx";

// Assets
import loginPageStyle from "./loginPageStyle.jsx"
import Table from "components/Table";

// actions
import {alertActions} from '_actions'

// @material-ui/core components
// core components
import Footer from "components/Footer";

import logoMini from "../assets/logo/Logo_Octiva_WHITE_RGB_no_text.png";
import Loader from "../components/Loader/Loader.jsx";

class WelcomePage extends React.Component {
  constructor(props) {
    super(props)
    // we use this to make the card to appear after the page has been rendered
    this.state = {
      cardAnimation: "cardHidden",
      bc: false,
    }
    this.loginClick = this.loginClick.bind(this)
  }

  // to stop the warning of calling setState of unmounted component
  componentWillUnmount() {
    var id = window.setTimeout(null, 0)
    while (id--) {
      window.clearTimeout(id)
    }
    clearTimeout(this.timeOutFunction)
    this.timeOutFunction = null
  }

  componentWillReceiveProps(newProps) {
    if (newProps.alert.type === 'ALERT_SUCCESS') {
      this.showNotification("bc")
    } else if (newProps.alert.type) {
      this.showNotification("bc")
    }
  }

  showNotification(place) {
    if (!this.state[place]) {
      var x = []
      x[place] = true
      this.setState(x)
      setTimeout(
        function () {
          x[place] = false
          this.setState(x)
        }.bind(this),
        6000
      )
    }
  }

  componentDidMount() {
    document.body.style.overflow = "unset";

    const {dispatch} = this.props;
    history.listen((location, action) => {
      // clear alert on location change
      dispatch(alertActions.clear());
    });
    // we add a hidden class to the card and after 700 ms we delete it and the transition appears
    this.timeOutFunction = setTimeout(
      function () {
        this.setState({
          cardAnimation: ""
        })
      }.bind(this),
      700
    )
  }

  loginClick(e) {
    // e.preventDefault()
    // const {dispatch} = this.props

    // dispatch(alertActions.clear())
    // history.push("/login")
    // console.log('userdata', this.auth)
    // open_login()

  }

  render() {
    const {classes, alert, loadingText} = this.props
    const {cardAnimation, bc} = this.state
    const backdropOpen = loadingText ? true : false
    var brandFooter = (
      <div>
        <a href={'https://' + window.location.hostname} className={classes.logoFooter}>
          <img src={logoMini} alt="logo" className={classes.imgFooter}/>
        </a>
      </div>
    );
    return (
      <div style={{overflow: "hidden"}}>
        <Backdrop className={classes.backdrop} open={backdropOpen} invisible={!backdropOpen}><Box
          justifyContent="center" alignItems="center" flexDirection="column"><Box flexShrink={1}><Loader/></Box>
          <p>{loadingText}</p></Box></Backdrop>
        <div className={classes.wrapper} ref="wrapper">
          <div
            className={classes.fullPage}
            style={{backgroundColor: "#638813"}}
          >
            <div className={classes.container}>
              <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={8} xl={8}>
                  <Card login className={classes[cardAnimation]}>
                    <CardHeader
                      className={`${classes.cardHeader} ${classes.textCenter}`}
                      color="primary"
                    >
                      <h4>
                        {"Welcome to Nimbus!"}
                      </h4>
                    </CardHeader>
                    <CardBody>
                      <div className={`${classes.center} ${classes.textCenter}`}>
                        This system is created by Octiva for monitoring and controlling the fleet.
                        <br/>
                        <br/>
                        Here are some interesting links:
                        <br/>
                        <br/>
                        <br/>
                        <Table
                          tableData={[
                            ["Device Management Dashboard",
                              <a href={"https://" + window.ROBOT_MANAGEMENT_LINK}>{window.ROBOT_MANAGEMENT_LINK}</a>],
                            ["Support Dashboard",
                              <a href={"https://" + window.SUPPORT_DASHBOARD_LINK}>{window.SUPPORT_DASHBOARD_LINK}</a>],
                            ["Device Live Data Graphs",
                              <a href={"https://" + window.LIVE_DATA_GRAPHS_LINK}>{window.LIVE_DATA_GRAPHS_LINK}</a>],
                            ["Device Text Logs",
                              <a href={"https://" + window.TEXT_LOG_GRAPHS_LINK}>{window.TEXT_LOG_GRAPHS_LINK}</a>],
                            ["", ""]
                          ]}
                        />
                      </div>
                    </CardBody>
                    <CardFooter className={classes.justifyContentCenter}>
                      <div className={classes.center} style={{textAlign: "center"}}>
                        <RedirectLogin/>
                      </div>
                    </CardFooter>
                  </Card>
                  <Snackbar
                    place="bc"
                    color={alert.type !== undefined ? alert.type.toLowerCase().indexOf("success") === -1 ? "warning" : "success" : "warning"}
                    icon={AddAlert}
                    message={alert.type !== undefined ? alert.message : ""}
                    open={bc}
                    closeNotification={() => this.setState({bc: false})}
                    close
                  />
                </GridItem>
              </GridContainer>
            </div>
            <Footer white/>
          </div>
          {brandFooter}
        </div>
      </div>
    )
  }
}

WelcomePage.propTypes = {
  classes: PropTypes.object.isRequired
}

function mapStateToProps(state) {
  const {loggingIn} = state.authentication
  const {alert} = state
  return {
    loggingIn, alert
  }
}

export default withStyles(loginPageStyle)(connect(mapStateToProps)(WelcomePage))
