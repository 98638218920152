import React, {useEffect} from "react";
import {useAuth} from "react-oidc-context";
import {Route, Router, Switch} from 'react-router-dom'
import {createBrowserHistory} from 'history'
import WelcomePage from 'routes/WelcomePage'
import Dashboard from "routes/Dashboard.jsx";

import 'assets/scss/material-dashboard-pro-react.css?v=1.4.0'
import "react-vis/dist/style.css"
import PrivateRoute from "./components/PrivateRoute";
import {store} from "_helpers/store"
import {useDispatch} from "react-redux";
import useWebSocket from "react-use-websocket";
import {userActions, deviceFleetActions} from "./_actions";
import {getAccessToken} from "./_services/authenticate";

export const history = createBrowserHistory();

function App() {
  const auth = useAuth()
  const state = store.getState()
  const dispatch = useDispatch()
  const tokenRenewalInterval = 5 * 60 * 1000 // 5 minutes

  const { sendJsonMessage, readyState } = useWebSocket(`${window.REACT_APP_WEBSOCKET_URL}/ws`, {
    onOpen: () => {
      sendJsonMessage({
        "message_type": "authentication",
        "access_token": getAccessToken()
      })
    },
    share: true,
    filter: () => false,
    retryOnError: true,
    shouldReconnect: () => true,
    onMessage: message => {
      let jsonMessage = JSON.parse(message.data)

      if (jsonMessage["type"] === "state"){
        dispatch(deviceFleetActions.updateDeviceState({[jsonMessage["serial"]]: {"state": jsonMessage["state"], "timestamp": jsonMessage["timestamp"]}}))
      } else if (jsonMessage["type"] === "data"){
        dispatch(deviceFleetActions.updateDeviceData(jsonMessage["serial"], {[jsonMessage["data_group"]]: jsonMessage["data"]}))
      }
    }
  });

  useEffect(() => {
    if (auth.isAuthenticated) {
      const handle = setInterval(() => {
        auth.signinSilent();
        const newUser = auth.user
        dispatch(userActions.updateUser(newUser))
      }, tokenRenewalInterval);
      return () => clearInterval(handle);
    }
  }, [auth, tokenRenewalInterval]);

  function WelcomePageWithState() {
    let loadingText = undefined;
    switch (auth.activeNavigator) {
      case "signinPopup":
        loadingText = "Signing you in in popup window..."
        break;
      default:
        break;
    }
    return (
      <WelcomePage loadingText={loadingText}/>
    )
  }

  if (state.authentication.user && auth.user) {
    return (<Router history={history}>
      <Switch>
        <Route exact path="/" component={WelcomePageWithState}/>
        <PrivateRoute exact component={Dashboard}/>
      </Switch>
    </Router>);
  }

  if (auth.isAuthenticated) {
    if (!state.authentication.user) {
      const newUser = auth.user
      dispatch(userActions.updateUser(newUser))
      history.push('/dashboard')
    }
  }

  return (
    <Router history={history}>
      <Switch>
        <Route exact path="/" component={WelcomePageWithState}/>
        <PrivateRoute exact component={Dashboard}/>
      </Switch>
    </Router>);
}

export default App;