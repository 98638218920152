import React from "react"
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles"

// core components
import GridContainer from "components/GridContainer"
import GridItem from "components/GridItem"
import Card from "components/Card"
import CardBody from "components/CardBody"
import CardHeader from "components/CardHeader"

// Assets
import userProfileStyles from "./userProfileStyles.jsx"

// Services
import {configService, deviceService} from '_services'
import {primaryColor} from "../../assets/jss/material-dashboard-pro-react";
import {filterSubstring} from "../helper_functions/table_filtering";
import Button from "../../components/CustomButtons";
import MyReactTable from "../../components/MyReactTable/MyReactTable";
import Datetime from "react-datetime";
import {Brightness1TwoTone, CancelOutlined} from "@material-ui/icons";
import {Alert, FormControlLabel} from "@mui/material";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/CheckCircleTwoTone";
import CustomInput from "../../components/CustomInput";
import Edit from "@material-ui/icons/Edit";
import {alertActions} from "../../_actions";
import {connect} from "react-redux";
import DeviceSelector from "./DeviceSelector";
import NavPills from "../../components/NavPills";
import MyDropdown from "../../components/MyDropdown";
import {getCognitoGroups, getUserId} from "../../_services/authenticate.js"
import {lumion_fix_translate_state} from "../helpers";


class DeviceConfiguration extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      edit_id: null,
      loading_events: false,
      device_events: [],
      device_config: {
        "file_names": [],
        "at_time": new Date().toISOString()
      },
      selected_file: "",
      changed_values: {},
      is_live: false
    }
    this.timer = null
    this._is_mounted = false
  }

  edit(key) {
    this.setState({edit_id: key})
  }

  cancel_edit() {
    this.setState({edit_id: null})
  }

  change_value(config_name, value) {
    this.setState({changed_values: {[config_name]: value}})
  }

  componentDidMount() {
    this._is_mounted = true
    const {device_serial} = this.props
    this.refresh(device_serial)
  }

  change(event, stateName) {
    this.setState({
      [stateName]: event.target.value
    })
  }

  confirm(config_name) {
    const {device_serial} = this.props
    const {selected_file} = this.state
    let value = config_name in this.state.changed_values ? this.state.changed_values[config_name] : null

    if (value !== null && selected_file !== "") {
      configService.setConfig(device_serial, selected_file, config_name, value, res => {
        this.refresh(device_serial)
      })

    }
    this.setState({changed_values: {}, edit_id: null})
  }

  componentWillUnmount() {
    this._is_mounted = false
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.device_serial !== this.props.device_serial) {
      this.refresh(this.props.device_serial)
    }
  }

  refresh(device_serial) {
    this.setState({loading_events: true, selected_file: undefined})
    deviceService.getDeviceConfig(device_serial, res => {
        this.setState({device_config: res.result})
      },
      reject => {
      })
    configService.getChanges(device_serial, res => {
        let events = res.result
        this.setState({device_events: events, loading_events: false})
      },
      reject => {
        this.setState({loading_events: false})
      })
    deviceService.getDeviceRawState(device_serial,  res => {
      let state = res.state
      this.setState({
        is_live: state.is_live,
      })
    })
  }

  reload_config(device_serial) {
    const {dispatch} = this.props
    deviceService.reloadConfig(device_serial, res => {
        dispatch(alertActions.success("The config is reloaded"))
      },
      reject => {
      })
  }

  download() {
    const {device_serial} = this.props
    const {at_time} = this.state
    configService.downloadConfigFolder(device_serial, at_time, res => {
    })
  }

  handleFile(name, value) {
    this.setState({
      "selected_file": value,
    })
  }

  handleDateChangeRaw = (e) => {
    e.preventDefault();
  }

  handle_date_selector(state_name, datetime) {
    this.setState({[state_name]: datetime.toISOString()})
  }

  render() {
    const {device_serial, classes, device_name, current_state} = this.props
    const {device_config, selected_file, at_time, edit_id, is_live} = this.state
    const {device_events, loading_events} = this.state

    let cognitoGroups = getCognitoGroups()
    let access_rights = ['*-*-read']
    if (cognitoGroups) {
      access_rights = getPermissionsFromGroups(cognitoGroups)
    }
    let configuration_list = []
    let usedConfig = {}
    if (selected_file !== undefined && selected_file !== "") {
      usedConfig = device_config[selected_file]
    } else {
      if (device_config.file_names.length > 0) {
        device_config.file_names.forEach(key => {
          const current_cfg = device_config[key]
          Object.keys(current_cfg).forEach(cfg_key => {
            usedConfig[cfg_key] = current_cfg[cfg_key]
          })
        })
      }
    }
    if (Object.keys(usedConfig).length !== 0) {
      Object.keys(usedConfig).forEach((key) => {
        let param_value = usedConfig[key]["value"]
        let command_name = usedConfig[key]["model"]["command_name"]
        let group = usedConfig[key]["model"]["group"].split("-")[0]
        let risk_level = usedConfig[key]["model"]["group"].split("-")[1]
        let param_type = usedConfig[key]["model"]["type"]

        if (typeof param_value === "boolean") {
          param_value = param_value ? "True" : "False"
        }

        let color = primaryColor
        if (risk_level && risk_level.toLowerCase() === "green")
          color = "rgb(100,173,30)"
        if (risk_level && risk_level.toLowerCase() === "yellow")
          color = "rgb(225,205,0)"
        if (risk_level && risk_level.toLowerCase() === "orange")
          color = "rgb(217,135,18)"
        if (risk_level && risk_level.toLowerCase() === "red")
          color = "rgb(211,0,0)"

        let edit = false
        let view = false
        let risk_level_access_list = access_rights.filter((access_right) => {
          return (access_right.split('-')[0] === group || access_right.split('-')[0] === '*') && (access_right.split('-')[1] === risk_level);
        })

        let scope = risk_level_access_list.map((access_right) => {
          return access_right.split('-')[2]
        })
        if (scope.includes("configure")) {
          edit = true
          view = true
        } else if (scope.includes('view')) {
          view = true
        }

        let actions = <Button justIcon round simple color={"primary"} onClick={this.edit.bind(this, key)}>
          <Edit/> </Button>
        let input_field = param_value

        if (key === edit_id) {
          actions = <div>
            <Button justIcon round simple color={"primary"} onClick={this.confirm.bind(this, key, key)}>
              <Check/> </Button>
            <Button justIcon round simple color={"danger"} onClick={this.cancel_edit.bind(this)}>
              <CancelOutlined/> </Button>
          </div>
          let value = key in this.state.changed_values ? this.state.changed_values[key] : param_value
          if (param_type === "bool") {
            input_field = <FormControlLabel
              control={
                <Checkbox
                  checked={value}
                  tabIndex={-1}
                  onClick={this.change_value.bind(this, key, !value)}
                  checkedIcon={<Check className={classes.checkedIcon}/>}
                  icon={<Check className={classes.uncheckedIcon}/>}
                  classes={{
                    checked: classes.checked,
                    root: classes.checkRoot
                  }}
                />
              }
              classes={{label: classes.label}}
              label={""}
            />
          } else if (param_type === "string") {
            input_field = <CustomInput
              id={key}
              labelText={""}
              inputProps={{
                onChange: event => this.change_value(key, event.target.value),
                type: "multiline",
                value: value
              }}
            />
          } else if (param_type === "float") {
            input_field = <CustomInput
              id={key}
              labelText={""}
              inputProps={{
                onChange: event => this.change_value(key, event.target.value),
                type: "number",
                value: value
              }}
            />
          } else if (param_type === "int") {
            input_field = <CustomInput
              id={key}
              labelText={""}
              inputProps={{
                onChange: event => this.change_value(key, event.target.value),
                type: "number",
                value: value
              }}
            />
          } else {
            input_field = "INPUT FIELD"
          }
        }

        if (view) {
          configuration_list.push({
            "name": key,
            "value": input_field,
            "command_name": command_name,
            "group": <div>
              <Brightness1TwoTone fontSize={"small"}
                                  style={{color: color, marginBottom: "-5px"}}/> {group}
            </div>,
            "param_type": param_type,
            "actions": is_live ? edit ? actions : <div>Not allowed to edit</div> : "Device is not online"
          })
        }
      })
    }


    let navPillTabs = [
      {
        tabButton: "Configuration",
        tabContent: <div>
          <Button color="primary"
                  onClick={this.refresh.bind(this, device_serial)}> Refresh
            Configuration </Button>
          <Button color="primary"
                  onClick={this.reload_config.bind(this, device_serial)}> Reload
            Config </Button>
          <MyDropdown
            input_label={"Choose File"}
            name={"fileSelect"}
            value={selected_file}
            handle_selection={
              this.handleFile.bind(this)
            }
            options={[
              ...device_config["file_names"].sort().map((item) => {
                return {
                  id: item,
                  value: item
                }
              })
            ]}
          />
          <p> {selected_file !== "" && usedConfig !== undefined ? `Showing config for ${selected_file}` : `Showing all config`}</p>

          <MyReactTable
            id={"device_config"}
            data={configuration_list}
            filterable
            columns={[
              {
                Header: "Name",
                accessor: "name",
                filterMethod: (filter, row) => filterSubstring(filter, row),
              },
              {
                Header: "Value",
                accessor: "value",
                filterMethod: (filter, row) => filterSubstring(filter, row),
              },
              {
                Header: "Group",
                accessor: "group",
                filterMethod: (filter, row) => filterSubstring(filter, row),
              },
              {
                Header: "Actions",
                accessor: "actions",
                filterMethod: (filter, row) => filterSubstring(filter, row),
              }
            ]}
            getTrProps={(state, rowInfo, column) => {
              if (rowInfo && rowInfo.row) {
                return {
                  style: {
                    background: rowInfo.row.level === "fatal" ? "rgba(255, 0, 0, 0.1)" : null
                  }
                }
              } else {
                return {}
              }
            }}
            defaultPageSize={10}
            showPaginationTop={false}
            showPaginationBottom={true}
            className=" -highlight"
          />
        </div>,
      },
      {
        tabButton: "History",
        tabContent: <div>
          <Button color="primary"
                  onClick={this.refresh.bind(this, device_serial)}>
            Refresh Data
          </Button>
          <MyReactTable
            id={"events"}
            data={device_events}
            filterable
            loading={loading_events}
            columns={[
              {
                Header: "Belgian Time",
                accessor: "belgian_local_time",
                filterMethod: (filter, row) => filterSubstring(filter, row),
                filterable: false,
                Cell: row => {
                  return new Date(row.value).toLocaleString("nl-BE")
                },
                maxWidth: 170
              },
              {
                Header: "Device Local Time",
                accessor: "device_local_time",
                filterMethod: (filter, row) => filterSubstring(filter, row),
                filterable: false,
                maxWidth: 170,
                Cell: row => {
                  if (row.original.device_local_timezone === null || row.value === "unknown") {
                    return "Unknown"
                  }
                  return new Date(row.value).toLocaleString("nl-BE", {timeZone: row.original.device_local_timezone})
                },

              },
              {
                Header: "Config Name",
                accessor: "config_name",
                filterMethod: (filter, row) => filterSubstring(filter, row),
              },
              {
                Header: "Old Value",
                accessor: "old_value",
                filterMethod: (filter, row) => filterSubstring(filter, row),
              },
              {
                Header: "New Value",
                accessor: "new_value",
                filterMethod: (filter, row) => filterSubstring(filter, row),
              }
            ]}
            getTrProps={(state, rowInfo, column) => {
              if (rowInfo && rowInfo.row) {
                return {
                  style: {
                    background: rowInfo.row.level === "fatal" ? "rgba(255, 0, 0, 0.1)" : null
                  }
                }
              } else {
                return {}
              }
            }}
            defaultPageSize={10}
            showPaginationTop={false}
            showPaginationBottom={true}
            className=" -highlight"
          />
        </div>,
      },
      {
        tabButton: "Download",
        tabContent: <div>
          <Datetime
            viewMode={"time"}
            locale={this.state.language}
            input={false}
            onChangeRaw={this.handleDateChangeRaw}
            onChange={this.handle_date_selector.bind(this, "at_time")}
            inputProps={{
              placeholder: "Select Time",
            }}
            initialValue={at_time}
          />
          <Button color="primary" onClick={this.download.bind(this)}> Download
            Configuration </Button>
        </div>,
      }
    ];


    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <DeviceSelector disable_key_bindings={true}/>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader><h4 style={{color: primaryColor}}><b>Device Configuration - {device_name}</b></h4>
            </CardHeader>
            <CardBody style={{overflow: 'hidden', padding: "12px 20px", maxWidth: "calc(100%-20px)"}}>
              <Alert severity={"info"}> Mind the device state, it is: <b>{current_state}</b></Alert>
              <NavPills
                tabs={navPillTabs}
              />

            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    )
  }
}

function mapStateToProps(state) {
  const {device_serial, all_devices, device_state} = state.device_fleet
  let in_control = false

  let current_state = "unknown"
  if (device_state && device_state[device_serial] && device_state[device_serial].state) {
    current_state = lumion_fix_translate_state(device_state[device_serial].state.current_state, device_state[device_serial].state.current_task)
  }

  if (device_state && device_state[device_serial] && device_state[device_serial].state) {
    in_control = device_state[device_serial].state.controller === getUserId()
  }

  let device_name = ""
  all_devices.forEach((device)=>{
    if (device["serial"] === device_serial){
      device_name = device["name"]
    }
  })

  return {
    device_serial, in_control, device_name, current_state
  }
}

function getPermissionsFromGroups(groups) {
  let access_rights = ["*-GREEN-view", "*-YELLOW-view", "*-ORANGE-view", "*-RED-view"];
  if (groups.includes('user_type:system_admin')) {
    access_rights = ["*-GREEN-configure", "*-YELLOW-configure", "*-ORANGE-configure", "*-RED-configure"]
  }
  if (groups.includes('user_type:license_support_engineer')) {
    access_rights = ["*-GREEN-configure", "*-YELLOW-configure", "*-ORANGE-configure", "*-RED-view"]
  }
  return access_rights
}

export default withStyles(userProfileStyles)(connect(mapStateToProps)(DeviceConfiguration))
