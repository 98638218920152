import React from "react"
import {connect} from 'react-redux'
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles"

// core components
import GridContainer from "components/GridContainer"
import GridItem from "components/GridItem"
import Card from "components/Card"
import CardBody from "components/CardBody"
import CardHeader from "components/CardHeader"

import extendedTablesStyle from "./extendedTablesStyle.jsx"

import {primaryColor} from "../../assets/jss/material-dashboard-pro-react";
import {systemService} from "../../_services";
import NavPills from "../../components/NavPills";
import Table from "../../components/Table";
import MyDropdown from "../../components/MyDropdown";
import {getUserType} from "../../_services/authenticate";
import Button from "../../components/CustomButtons";
import CustomInput from "../../components/CustomInput";
import {Alert} from "@mui/material";
import {sweetAlertActions} from "../../_actions";

class Authorisation extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      error_message: "",
      warning_message: "",
      system_admins: [],
      license_support_engineers: [],
      organisation_id: null,
      organisation_users: [],
      email: null,
      user_type: null,
    }

    this.timer = null
    this._is_mounted = false
    this._refresh_rate = 3000
  }

  componentDidMount() {
    this._is_mounted = true
    this.refresh()
  }

  componentWillUnmount() {
    clearTimeout(this.timer)
    this._is_mounted = false
  }

  invite_user() {
    const {dispatch} = this.props
    const {organisation_id, user_type, email} = this.state
    let org_id = organisation_id
    if (user_type === "license_support_engineer" || user_type === "system_admin") {
      org_id = null
    }

    systemService.inviteUser(org_id, email, user_type, res =>{
      this.setState({
        user_type: null,
        email: null,
        organisation_id: org_id
      })

      dispatch(sweetAlertActions.success({
        title: "User Invited",
        content: <div>
          <p>This user has been invited and will receive an email with the invitation to join.</p>
        </div>
      }))
    }, error=>{
      dispatch(sweetAlertActions.danger({
        title: "User is not invited",
        connect: <div>
          Ask your Nimbus administrator for help!
          Error: {error}
        </div>
      }))
    })
  }

  validateEmail(email) {
    return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )
  }

  refresh() {
    const {organisation_id, user_type, email} = this.state
    let error_message = ""
    let warning_message = ""
    if (user_type === "system_admin" && getUserType() !== "system_admin") {
      error_message = "Only system admins can invite another system admin"
    } else {
      if (user_type === "license_support_engineer" && organisation_id !== null) {
        warning_message = "Organisation will be ignored when inviting a license support engineer"
      }
      if ((user_type === "organisation_admin" || user_type === "organisation_user") && organisation_id === null) {
        error_message = "An organisation needs to be selected when adding an organisation user or admin"
      }
    }

    if (email === null || email === undefined || email === "" || !this.validateEmail(email)) {
      error_message = "Email address is not valid"
    }
    this.setState({
      error_message, warning_message
    })
    systemService.getSystemAdmins(res => {
      this.setState({system_admins: res.system_admins})
    })
    systemService.getLicenseSupportEngineers(res => {
      this.setState({license_support_engineers: res.license_support_engineers})
    })
    if (organisation_id !== null) {
      systemService.getUsersForOrganisation(organisation_id, res => {
        this.setState({organisation_users: res.users})
      })
    }
  }

  handle_form_input_selector(name, value) {
    this.setState({[name]: value}, this.refresh.bind(this))
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.organisations !== this.props.organisations || prevProps.locations !== this.props.locations || prevProps.all_devices !== this.props.all_devices || prevProps.deployments !== this.props.deployments) {
      this.refresh()
    }
  }

  render() {
    const {organisations} = this.props
    const {
      error_message,
      warning_message,
      system_admins,
      license_support_engineers,
      organisation_id,
      organisation_users
    } = this.state

    let tabs = [
      {
        tabButton: "System Admins",
        tabContent: <Card>
          <CardHeader>
            <GridContainer>
              <GridItem xs={10}>
                <h4 style={{color: primaryColor}}>
                  <b>{"System Admins"}</b></h4>
              </GridItem>
            </GridContainer>
          </CardHeader>
          <CardBody>
            <GridContainer>
              <GridItem xs={12}>
                <Table
                  tableData={[
                    [<b>Username</b>, <b>Email</b>, <b>Status</b>, <b>Enabled</b>, <b>User Created</b>],
                    ...system_admins.map(user => {
                      return [user['username'], user['email'], user["status"], user["enabled"] ? "Yes" : "No", user["created"]]
                    }),
                    ["", "", "", "", ""]
                  ]}
                />
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      },
      {
        tabButton: "License Support Engineers",
        tabContent: <Card>
          <CardHeader>
            <GridContainer>
              <GridItem xs={10}>
                <h4 style={{color: primaryColor}}>
                  <b>{"License Support Engineers"}</b></h4>
              </GridItem>
            </GridContainer>
          </CardHeader>
          <CardBody>
            <GridContainer>
              <GridItem>
                <Table
                  tableData={[
                    [<b>Username</b>, <b>Email</b>, <b>Status</b>, <b>Enabled</b>, <b>User Created</b>],
                    ...license_support_engineers.map(user => {
                      return [user['username'], user['email'], user["status"], user["enabled"] ? "Yes" : "No", user["created"]]
                    }),
                    ["", "", "", "", ""]
                  ]}
                />
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      },
      {
        tabButton: "Organisation Users",
        tabContent: <Card>
          <CardHeader>
            <GridContainer>
              <GridItem xs={10}>
                <h4 style={{color: primaryColor}}>
                  <b>{"Organisation Users"}</b></h4>
              </GridItem>
            </GridContainer>
          </CardHeader>
          <CardBody>
            <GridContainer>
              <GridItem xs={12}>
                <MyDropdown
                  input_label={"Organisation"}
                  name={"organisation_id"}
                  value={organisation_id}
                  default_value={organisation_id}
                  options={[...Object.keys(organisations).sort((a, b) => {
                    return organisations[a]["name"] < organisations[b]["name"] ? -1 : 1
                  }).map((org_id) => {
                    return {id: org_id, value: organisations[org_id].name}
                  })]}
                  handle_selection={this.handle_form_input_selector.bind(this)}
                />
              </GridItem>
              <GridItem xs={12}>
                <Table
                  tableData={[
                    [<b>Username</b>, <b>Email</b>, <b>Status</b>, <b>Enabled</b>, <b>User Created</b>],
                    ...organisation_users.map(user => {
                      return [user['username'], user['email'], user["status"], user["enabled"] ? "Yes" : "No", user["created"]]
                    }),
                    ["", "", "", "", ""]
                  ]}
                />
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      },
      {
        tabButton: "Manage Users",
        tabContent: <Card>
          <CardHeader>
            <GridContainer>
              <GridItem xs={10}>
                <h4 style={{color: primaryColor}}>
                  <b>{"Manage Users"}</b></h4>
              </GridItem>
            </GridContainer>
          </CardHeader>
          <CardBody>
            <GridContainer>
              <GridItem xs={12}>
                <CustomInput
                  labelText={"Email Address"}
                  inputProps={{
                    onChange: event => this.handle_form_input_selector("email", event.target.value),
                    type: "email",
                    value: this.state["email"]
                  }}
                />
                <MyDropdown
                  input_label={"User Type"}
                  name={"user_type"}
                  value={this.state["user_type"]}
                  default_value={this.state["user_type"]}
                  options={[
                    {id: "system_admin", value: "System Admin"},
                    {id: "license_support_engineer", value: "License Support Engineer"},
                    {id: "organisation_admin", value: "Organisation Admin"},
                    {id: "organisation_user", value: "Organisation User"},
                  ]}
                  handle_selection={this.handle_form_input_selector.bind(this)}
                />
                <MyDropdown
                  input_label={"Organisation"}
                  name={"organisation_id"}
                  value={organisation_id}
                  default_value={organisation_id}
                  options={[{id: null, value: "No organisation"}, ...Object.keys(organisations).sort((a, b) => {
                    return organisations[a]["name"] < organisations[b]["name"] ? -1 : 1
                  }).map((org_id) => {
                    return {id: org_id, value: organisations[org_id].name}
                  })]}
                  handle_selection={this.handle_form_input_selector.bind(this)}
                />

                {error_message !== "" &&
                  <Alert severity={"error"}> {error_message}</Alert>
                }

                {warning_message !== "" &&
                  <Alert severity={"warning"}> {warning_message}</Alert>
                }

                <Button simple color={"primary"} disabled={error_message !== ""} onClick={this.invite_user.bind(this)}> Invite User </Button>
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      }
    ]
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <NavPills
            tabs={tabs}
          />
        </GridItem>
      </GridContainer>
    )
  }
}


function mapStateToProps(state) {
  const {organisations, locations, deployments} = state.location
  const {all_devices} = state.device_fleet
  return {
    organisations, locations, all_devices, deployments
  }
}


export default withStyles(extendedTablesStyle)(connect(mapStateToProps)(Authorisation))
