import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import {Redirect, Route, Switch} from "react-router-dom";
import {withCookies} from "react-cookie";
import {connect} from 'react-redux';
import SweetAlert from "react-bootstrap-sweetalert";
// import {store} from "_helpers/store";
import queryString from "query-string";


// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import Header from "components/Header";
import Footer from "components/Footer";
import Sidebar from "components/Sidebar";

import dashboardRoutes from "routes/DashboardRoutes";

// assets
import appStyle from "./dashboardStyle.jsx";

import {deviceFleetActions, sweetAlertActions} from "_actions"

import logo from 'assets/logo/Logo_Octiva_WHITE_RGB_HR.png'
import logoMini from "assets/logo/Logo_Octiva_WHITE_RGB_no_text.png"
import {update_device_lists, update_error_codes} from "../views/helper_functions/device_icons";
import {
  update_deployment_list,
  update_greenhouse_list,
  update_location_list,
  update_map_list,
  update_organisation_list
} from "../views/helper_functions/locations";

let ps;

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileOpen: false,
      miniActive: false,
      sweet_alert: null,
      allRoutes: []
    };
    this.resizeFunction = this.resizeFunction.bind(this);
    this.timer = null
    this.fast_timer = null

    this.myMainPanelRef = React.createRef();

    this._is_mounted = false
    this._refresh_rate = 50000
    this._fast_refresh_rate = 2000
  }

  componentDidMount() {
    this._is_mounted = true
    this.setState({loading: true})

    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(this.myMainPanelRef.current, {
        suppressScrollX: true,
        suppressScrollY: false
      });
      document.body.style.overflow = "hidden";
    }
    window.addEventListener("resize", this.resizeFunction);

    const {dispatch} = this.props
    let device_serial = localStorage.getItem("selected_device_serial")
    if (device_serial !== null && device_serial !== undefined) {
      dispatch(deviceFleetActions.updateSerial(device_serial))
    }

    this.refresh()
    this.fast_refresh()
  }

  componentWillUnmount() {
    clearTimeout(this.timer)
    clearTimeout(this.fast_timer)
    this._is_mounted = false
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
    }
    window.removeEventListener("resize", this.resizeFunction);
  }

  refresh() {
    update_device_lists()
    update_error_codes()

    update_organisation_list()
    update_greenhouse_list()
    update_location_list()
    update_map_list()
    update_deployment_list()

    if (this._is_mounted) {
      this.timer = setTimeout(() => this.refresh(), this._refresh_rate)
    }
  }

  fast_refresh() {
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.location) {
      const {device_serial} = queryString.parse(this.props.location.search)
      if (device_serial !== undefined && device_serial !== null) {
        this.props.dispatch(deviceFleetActions.updateSerial(device_serial))
      }
    }

    if (prevProps.location !== undefined && this.props.location !== undefined) {
      if (prevProps.location.pathname !== this.props.location.pathname) {
        this.myMainPanelRef.current.scrollTop = 0;
        if (this.state.mobileOpen) {
          this.setState({mobileOpen: false});
        }
      }
    }
  }

  handleDrawerToggle = () => {
    this.setState({mobileOpen: !this.state.mobileOpen});
  };

  sidebarMinimize() {
    this.setState({miniActive: !this.state.miniActive});
  }

  resizeFunction() {
    if (window.innerWidth >= 960) {
      this.setState({mobileOpen: false});
    }
  }

  get_user_type(user){
    let group_str = user.profile["cognito:groups"].filter((group) => group.indexOf("user_type") >= 0).toString()
    return group_str.split(":")[1]
  }

  componentWillMount() {
    const {user} = this.props
    let newRoutes = [...dashboardRoutes.filter((route) => {
      if (route["roles"] !== undefined) {
        let user_type = this.get_user_type(user)
        return route["roles"].includes(user_type)
      }

      return true
    })]

    newRoutes.push({
      redirect: true,
      path: "/",
      pathTo: newRoutes[0].path,
      name: newRoutes[0].name
    })
    this.setState({allRoutes: newRoutes})

  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.sweet_alert) {
      if (nextProps.sweet_alert.type) {
        this.showSweetAlert(nextProps.sweet_alert.type, nextProps.sweet_alert.options)
      } else {
        this.setState({
          sweet_alert: null
        });
      }
    }
  }

  showSweetAlert(type, options) {
    const {classes} = this.props
    this.setState({
      sweet_alert:
        <SweetAlert
          danger={type === 'sweet_alert-danger'}
          warning={type === 'sweet_alert-warning'}
          success={type === 'sweet_alert-success'}
          info={type === 'sweet_alert-info'}

          style={{display: "block", marginTop: "10px"}}
          title={options.title}

          onConfirm={options.onConfirm ? options.onConfirm.bind(this) : this.hideAlert.bind(this)}
          confirmBtnCssClass={classes ? classes.button + " " + classes.successBtn : ''}
          confirmBtnText={options.confirmBtnText}

          cancelBtnText={options.cancelBtnText}
          onCancel={options.onCancel ? options.onCancel.bind(this) : this.hideAlert.bind(this)}
          cancelBtnCssClass={classes ? classes.button : ''}

          showCancel={options.showCancel}
          closeOnClickOutside={options.closeOnClickOutside}
        >
          {options.content}
        </SweetAlert>
    })
  }

  hideAlert() {
    this.setState({
      sweet_alert: null
    });
    this.props.dispatch(sweetAlertActions.clear())
  }

  render() {
    const {classes, ...rest} = this.props;
    const {sweet_alert, allRoutes} = this.state;
    const mainPanel =
      classes.mainPanel +
      " " +
      cx({
        [classes.mainPanelSidebarMini]: this.state.miniActive,
        [classes.mainPanelWithPerfectScrollbar]:
        navigator.platform.indexOf("Win") > -1
      });

    return (
      <div className={classes.wrapper}>
        <Sidebar
          routes={this.state.allRoutes}
          logo={logo}
          logoMini={logoMini}
          handleDrawerToggle={this.handleDrawerToggle}
          open={this.state.mobileOpen}
          color="primary"
          bgColor="black"
          miniActive={this.state.miniActive}
          {...rest}
        />
        <div className={mainPanel} ref={this.myMainPanelRef}>
          <Header
            sidebarMinimize={this.sidebarMinimize.bind(this)}
            miniActive={this.state.miniActive}
            routes={this.state.allRoutes}
            handleDrawerToggle={this.handleDrawerToggle}
            {...rest}
          />
          {/* On the /maps/full-screen-maps route we want the map to be on full screen - this is not possible if the content and container classes are present because they have some paddings which would make the map smaller */}
          <div className={classes.content}>
            <div className={classes.container}>
              <Switch>
                {allRoutes.map((prop, key) => {
                  if (prop.redirect)
                    return <Redirect from={prop.path} to={prop.pathTo} key={key}/>;
                  if (prop.collapse)
                    return prop.views.map((prop, key) => {
                      return (
                        <Route path={prop.path} component={prop.component} key={key}
                               hidden={prop.hidden}/>
                      );
                    });
                  return <Route path={prop.path} component={prop.component} key={key}/>;
                })}
              </Switch>
            </div>
          </div>
          <Footer fluid/>
          {sweet_alert}
        </div>
      </div>
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  const {sweet_alert} = state;
  const {device_serial} = state.device_fleet;
  const {user} = state.authentication
  return {
    sweet_alert, device_serial, user
  }
}

export default withCookies(withStyles(appStyle)(connect(mapStateToProps)(Dashboard)))
