import React from "react"
import Button from "../CustomButtons"
import {useAuth} from "react-oidc-context"

const RedirectLogin = () => {
  const auth = useAuth()

  const handleLogin = () => {
    auth.signinPopup({popupWindowFeatures: {width: 450, height: 600, left: 150, top: 100}})
  }
  return (
    <Button color="primary" onClick={() => {
      handleLogin()
    }}>Log in</Button>
  )
}

export default RedirectLogin